<template>
  <div class="constrain py-8 lg:py-24 relative z-30">
    <h2 class="heading-2">Customer testimonials</h2>

    <div class="flex justify-between lg:justify-start lg:space-x-4 items-center mt-base">
      <p class="text-[#938394] text-[18px]">
        250+ happy customers
      </p>

      <NuxtLink
        to="https://uk.trustpilot.com/review/justhire.com"
        class="text-primary font-bold text-base"
        target="_blank"
        rel="noopener noreferrer"
      >
        View all reviews
      </NuxtLink>
    </div>

    <!-- Large screens grid layout -->
    <div class="hidden lg:grid grid-cols-2 gap-12 mt-16">
      <div
        class="bg-light-green rounded-2xl p-8"
        v-for="testimonial in testimonials"
        :key="testimonial.name"
      >
        <div class="flex justify-between">
          <NuxtImg
            provider="storyblok"
            class="w-32"
            :src="TRUSTPILOT_STARS_URL"
            alt="Trustpilot Stars"
            loading="lazy"
            format="webp"
          />
          <p class="text-neutrals-600">{{ testimonial.date }}</p>
        </div>
        <p class="subtitle-2 mt-6">{{ testimonial.reviewSummary }}</p>
        <hr class="my-base">
        <p class="text-base-black mt-4">{{ testimonial.fullReview }}</p>
        <div class="text-center mt-2 text-neutrals-600">
          <p>{{ testimonial.name }}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- Small screens carousel layout -->
  <div class="lg:hidden px-6 py-12 bg-light-green relative">
    <div class="bg-light-green rounded-2xl p-l min-h-[333px]">
      <div class="flex justify-between">
        <NuxtImg
          provider="storyblok"
          class="w-32"
          :src="TRUSTPILOT_STARS_URL"
          alt="Trustpilot Stars"
          loading="lazy"
          format="webp"
        />
        <p class="text-neutrals-600">{{ testimonials[currentIndex].date }}</p>
      </div>
      <p class="subtitle-2 mt-6">{{ testimonials[currentIndex].reviewSummary }}</p>
      <hr class="my-base">
      <p class="text-base-black mt-4">{{ testimonials[currentIndex].fullReview }}</p>
      <div class="text-center mt-2 text-neutrals-600">
        <p>{{ testimonials[currentIndex].name }}</p>
      </div>
    </div>

    <!-- Carousel navigation buttons -->
    <div class="flex justify-between items-center p-l">
      <button
        class="p-4 border border-grey-3 rounded-full"
        @click="prevTestimonial"
      >
        <CommonSVGTemplate
          width="25"
          height="25"
          alt="Left Arrow"
          :path="leftArrow"
        />
      </button>

      <!-- Carousel dots -->
      <div class="flex space-x-2">
        <span
          v-for="(testimonial, index) in testimonials"
          :key="testimonial.name"
          @click="currentIndex = index"
          :class="{
            'h-2 w-2 bg-primary rounded-full': index === currentIndex,
            'h-2 w-2 bg-[#E0E2E7] rounded-full': index !== currentIndex
          }"
        ></span>
      </div>

      <button
        class="p-4 border border-grey-3 rounded-full"
        @click="nextTestimonial"
      >
        <CommonSVGTemplate
          width="25"
          height="25"
          alt="Right Arrow"
          :path="rightArrow"
        />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
const testimonials = [
  {
    name: "Ronald Johnson",
    reviewSummary: "After a minor initial problem with my…",
    fullReview: "After a minor initial problem with my on-line order, which was quickly solved by Just Hire, the whole experience of hiring the skip went just as one would have wanted. The skip was delivered...",
    date: "A Day Ago",
  },
  {
    name: "Sonia Scott",
    reviewSummary: "Convenient Skip Hire",
    fullReview: "I hired a skip online and was able to choose the dates I wanted. Good price and seamless drop off and pick up. Excellent email communication reminder of collection and I could have extended the hire time easily. Friendly staff, excellent service.",
    date: "A Day Ago",
  },
  {
    name: "Tracy",
    reviewSummary: "Ordered skip online no problems",
    fullReview: "Ordered skip online no problems\n When it arrived the delivery man was very nice, suggested best place for it to go explained all...",
    date: "13 Aug 2024",
  },
  {
    name: "Barry Tait",
    reviewSummary: "Hassle free hire",
    fullReview: "Hired a skip from just hire for a garden clearance, skip arrived when it was meant to and was collected early when it was full. Hassle free booking and great",
    date: "12 Aug 2024",
  }
];

// Index to track the current testimonial for small screens
const currentIndex = ref(0);

// Function to go to the previous testimonial
const prevTestimonial = () => {
  currentIndex.value = currentIndex.value === 0 ? testimonials.length - 1 : currentIndex.value - 1;
};

// Function to go to the next testimonial
const nextTestimonial = () => {
  currentIndex.value = currentIndex.value === testimonials.length - 1 ? 0 : currentIndex.value + 1;
};

const leftArrow = `
<path d="M9.90398 5.93018L3.83398 12.0002L9.90398 18.0702" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.8339 12H4.00391" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
`;

const rightArrow = `
<path d="M14.7637 5.93018L20.8337 12.0002L14.7637 18.0702" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.83398 12H20.664" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
`;

const TRUSTPILOT_STARS_URL = "/f/237253/512x96/e1a421405b/trust-stars.png";
</script>
